import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { getUser, isLoggedIn } from '../services/auth';
import Header from './header';
import Footer from './footer';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Layout = ({ children, noticeText, noticeLink, noticeLinkText }) => {
  // useEffect(() => {
  //   AOS.init({
  //     once: true,
  //   })
  // }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  // if (isLoggedIn() && !getUser().lastPayment) {
  //   navigate("/app/welcome")
  // }

  return (
    <div>
      <Header
        noticeText={noticeText}
        noticeLink={noticeLink}
        noticeLinkText={noticeLinkText}
      />
      {children}
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  noticeText: 'Register now for the next ACAIN event',
  noticeLink: 'https://forms.gle/ybbC18n1VnEirhqZA',
  noticeLinkText: 'Register'
};

export default Layout;

import React from 'react';
import { Link } from 'gatsby';
import * as styles from './footer.module.scss';
import logo from '../svgs/logo-header.svg';
import facebook from '../svgs/facebook.svg';
import twitter from '../svgs/twitter.svg';
import instagram from '../svgs/instagram.svg';
import linkedin from '../svgs/linkedin.svg';

const nav = {
  home: { to: '/', text: 'Home' },
  'about-us': { to: '/about-us', text: 'About us' },
  Membership: { to: '/membership', text: 'Membership' },
  Publications: { to: '/publications', text: 'Publications' },
  Directory: { to: '/mirectory', text: 'Directory' },
  'News-&-Events': { to: '/News-&-Events', text: 'News & Events' },
  Contact: { to: '/contact', text: 'Contact' }
};

const getYear = () => {
  const d = new Date();
  let year = d.getFullYear();
  return year;
};

const FooterLink = (props) => (
  <li className={styles.footer__navItem}>
    <Link to={props.to} className={styles.footer__navlink}>
      {props.text}
    </Link>
  </li>
);

export default () => {
  return (
    <div className={styles.footer}>
      <img src={logo} alt="logo" />
      <div className={styles.footer__title}>
        Association of Children’s Authors and Illustrators of Nigeria
      </div>
      <nav className={styles.footer__navmenu}>
        {/* <div className={styles. footer__hr}></div> */}
        <ul className={styles.footer__nav}>
          {Object.values(nav).map((menu, idx) => (
            <FooterLink to={menu.to} text={menu.text} key={idx} />
          ))}
        </ul>

        {/* <div className={styles. footer__hr}></div> */}
      </nav>
      <div className={styles.footer__copyright}>
        Copyright © {getYear()} ACAIN. All Rights Reserved.
      </div>

      <h5 className={styles.footer__socialTitle}>Follow us on Social Media</h5>
      <span className={styles.footer__socialTitle} style={{ fontSize: 12 }}>
        @OfficialACAIN
      </span>
      <div className={styles.footer__socialLink}>
        <a
          href="https://web.facebook.com/Official_ACAIN-107032121477241"
          target="_blank"
          rel="noreferrer noopener"
          className={styles.footer__socialLink__item}
        >
          <img src={facebook} alt="facebook icon" />
        </a>
        <a
          href="https://twitter.com/official_acain"
          target="_blank"
          rel="noreferrer noopener"
          className={styles.footer__socialLink__item}
        >
          <img src={twitter} alt="twitter icon" />
        </a>
        <a
          href="https://www.instagram.com/officialacain/"
          target="_blank"
          rel="noreferrer noopener"
          className={styles.footer__socialLink__item}
        >
          <img src={instagram} alt="instagram icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/association-of-children-s-authors-and-illustrators-of-nigeria-9a86a3209/"
          target="_blank"
          rel="noreferrer noopener"
          className={styles.footer__socialLink__item}
        >
          <img src={linkedin} alt="linkedin icon" />
        </a>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import MobileMenu from './mobileMenu';
import HeaderLinkList from './HeaderLinkList';
import {
  isLoggedIn,
  logout,
  getUser,
  handleUnAuthorizedError
} from '../services/auth';
import { navigate } from 'gatsby';
import * as styles from './header.module.scss';
import logo from '../svgs/logo-header.svg';
import avatar from '../svgs/avatar.svg';
import expand from '../svgs/expand_more_24px.svg';

const nav = {
  home: { to: '/', text: 'Home' },
  'about-us': { to: '/about-us', text: 'About us' },
  Membership: {
    to: '#',
    text: 'Membership',
    dropdown: true,
    dropdownMenu: ['Types of Membership', 'Membership benefits']
  },
  Publications: { to: '/publications', text: 'Publications' },
  Directory: {
    to: '#',
    text: 'Directory',
    dropdown: true,
    dropdownMenu: ['Members directory', 'Professional directory']
  },
  'News-&-Events': {
    to: '#',
    text: 'News & Events',
    dropdown: true,
    dropdownMenu: ['Ake Festival', 'Akada Book Festival', 'Gallery']
  },
  Contact: { to: '/contact', text: 'Contact' }
};

// let topNotice = {
//   text: 'Register now for the next ACAIN event',
//   link: 'https://forms.gle/ybbC18n1VnEirhqZA',
//   linkText: 'Register'
// };

// if (window.location.pathname === '/publications/') {
//   topNotice.text = 'View book stores in Nigeria';
//   topNotice.link =
//     'https://docs.google.com/spreadsheets/d/1QJ1ce9KxEjRmHNRE0ufzQhA8zPlVUlLd/edit?usp=sharing&ouid=110588503441816651200&rtpof=true&sd=true';
//   topNotice.linkText = 'Here';
// }

function Header({ noticeText, noticeLink, noticeLinkText }) {
  const [profile, setProfile] = useState();

  const { access_token } = getUser();

  const config = {
    headers: { Authorization: `Bearer ${access_token}` }
  };

  useEffect(() => {
    if (!isLoggedIn()) {
      return;
    }

    (async () => {
      try {
        const response = await axios.get(
          'https://acain.herokuapp.com/api/v1/profile',
          config
        );
        const { data } = response.data;
        setProfile(data);
      } catch (error) {
        console.log(error.response);
        handleUnAuthorizedError(error.response.status);
      }
    })();
    /* eslint-disable */
  }, []);

  // const { firstname } = getUser()
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: 16,
          background: '#c92a31',
          padding: 16
        }}
        // className={styles.notification}
      >
        <p style={{ margin: 'unset', color: '#fff', fontSize: 18 }}>
          {noticeText}
        </p>
        <a
          href={noticeLink}
          target="_blank"
          rel="noreferrer noopener"
          style={{
            background: '#fff',
            color: '#c92a31',
            textDecoration: 'none',
            padding: 8,
            borderRadius: 8
          }}
        >
          {noticeLinkText}
        </a>
      </div>
      <header className={styles.header}>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>

        <nav className={styles.navDesktop}>
          <ul className={styles.navDesktop__menuNav}>
            <HeaderLinkList nav={nav} />
          </ul>
        </nav>

        {!isLoggedIn() ? (
          <div className={styles.authLink}>
            <Link to="/sign-in" className={styles.authLink__signin}>
              Sign in
            </Link>
            <Link to="/join" className={styles.authLink__join}>
              Join
            </Link>
          </div>
        ) : (
          <div className={styles.user}>
            <img
              src={profile && profile.avatar ? profile.avatar : avatar}
              alt="avatar"
              className={styles.user__image}
            />

            <span className={styles.user__text}>
              Welcome, {profile && profile.firstname}
            </span>
            <span>
              <img src={expand} alt="" />
            </span>

            <div className={styles.user__dropdown}>
              <ul className={styles.droplink}>
                <li className={styles.droplink__item}>
                  <Link to="/app/profile" className={styles.droplink__link}>
                    Profile
                  </Link>
                </li>
                <li className={styles.droplink__item}>
                  <Link to="/app/books" className={styles.droplink__link}>
                    Books
                  </Link>
                </li>
                <li className={styles.droplink__item}>
                  <Link
                    to="#"
                    className={styles.droplink__link}
                    onClick={(event) => {
                      event.preventDefault();
                      logout(() => navigate(`/sign-in`));
                    }}
                  >
                    Sign out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}

        <MobileMenu navItems={Object.values(nav)} profile={profile} />
      </header>
    </>
  );
}

export default Header;

// extracted by mini-css-extract-plugin
export var header = "header-module--header--knWBH";
export var navDesktop = "header-module--navDesktop--WVUMF";
export var navDesktop__menuNav = "header-module--navDesktop__menuNav--FOyUU";
export var navDesktop__item = "header-module--navDesktop__item--fH+gm";
export var navDesktop__link = "header-module--navDesktop__link--nWGu8";
export var authLink = "header-module--authLink--f0nQ+";
export var authLink__signin = "header-module--authLink__signin--FK1T0";
export var authLink__join = "header-module--authLink__join--LZB2U";
export var user = "header-module--user--nOtNJ";
export var user__image = "header-module--user__image--oBkCN";
export var user__text = "header-module--user__text--jnDyN";
export var user__dropdown = "header-module--user__dropdown--+7-Nn";
export var droplink = "header-module--droplink--7jAE9";
export var droplink__item = "header-module--droplink__item--gW7Av";
export var droplink__link = "header-module--droplink__link--vGGtO";